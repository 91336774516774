import userApi from '../api/user'
import store from '../store/store'
export default (to, from, next) => {
  const session = localStorage.getItem("session") ? JSON.parse(localStorage.getItem("session")) : {};
  if (session != null && session.access_token) {
    userApi.fetchUserCallback((request) => {
      request.then((suss) => {
        const {data} = suss;
        /* User logged */
        if (data && data.data.id) {
          store.commit("setUserData", suss.data);
          //store.dispatch('fetchUserProfile');
          if(session.tenant){
            store.commit("setCurrentTenant", session.tenant);
          }
        }

        if (JSON.stringify(session.tenantUser.roles).includes('ROLE_TENANT_ADMIN')) {
          store.commit("setCurrentUserType", store.getters['userTypes'][2]); //Tenant Admin
        } else if (JSON.stringify(session.tenantUser.roles).includes('ROLE_ADMIN')) {
          store.commit("setCurrentUserType", store.getters['userTypes'][0]); //Master Admin
        } else {
          store.commit("setCurrentUserType", store.getters['userTypes'][1]); //Tenant User
        }

        next();
      }).catch(() => {
        next({
          path: '/signin',
          query: { redirect: to.fullPath }
        });
      });
    });
  } else {
    localStorage.removeItem("session");
    next({
      path: '/signin',
      query: { redirect: to.fullPath }
    });
  }
};
