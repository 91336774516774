import axios from '@/plugins/axios'

const Products = {
    all() {
        return axios({ requiresAuth: false }).get('product');
    },
    show(id){
        return axios({ requiresAuth: false }).get(`product/${id}`);
    },
    somethingLike(name){
        return axios({ requiresAuth: false }).get(`product/something-like/${name}`);
    }
}

export default Products