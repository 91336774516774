const Roles = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    /* async checkMenuPermissions(_, menuItem) {
      console.log(menuItem);
      return false;
    },  */
  },
};
export default Roles;
