import axios from "@/plugins/axios"

const Module = {
    all(){
        return axios({requiresAuth: true}).get("api/modules");
    },
    uploadFile(tenantId, data){
        const formData = new FormData();

        formData.append('data_file', data.data_file);
        formData.append('module_id', data.module_id);
        formData.append('document_type', data.document_type);


        return axios({requiresAuth: true}).post(`api/tenants/${tenantId}/manage_data`, formData, {
            'Content-Type': 'multipart/form-data',
            timeout: 900000
        });
    },
}

export default Module;