const Navigation = {
  state: {
    drawer: false,
    distribuyeUrl: process.env.NODE_ENV == "development"
    ? `http://localhost:8200/api/`
    : `https://distribuye.herokuapp.com/auth/login`,
    menu: [
      {
        key: 'home',
        text: "generals.home",
        route: "/",
        icon: "mdi-home-group",
        id: 1,
        requireAuth: true,
        tenantUserAllowed: true,
      },
      {
        key: 'tenancy',
        text: "generals.tenancy",
        route: "/tenant/",
        icon: "mdi-home-group",
        id: 2,
        requireAuth: true,
        tenantUserAllowed: true,
      },
        //ALLEC-330
      // {
      //   key: 'company',
      //   text: "generals.company",
      //   route: "/company/",
      //   icon: "mdi-domain",
      //   id: 3,
      //   requireAuth: true,
      //   tenantUserAllowed: true,
      // },
      // {
      //   key: 'location',
      //   text: "generals.locations",
      //   route: "/location/",
      //   icon: "mdi-map-marker-multiple-outline",
      //   id: 4,
      //   requireAuth: true,
      //   tenantUserAllowed: true,
      // },
      // {
      //   key: 'invoice',
      //   text: "generals.invoices",
      //   route: "/invoice/",
      //   icon: "mdi-receipt",
      //   id: 5,
      //   requireAuth: true,
      //   tenantUserAllowed: true,
      // },
      // {
      //   key: 'supplier',
      //   text: "generals.suppliers",
      //   route: "/supplier/",
      //   icon: "mdi-truck-fast-outline",
      //   id: 6,
      //   requireAuth: true,
      //   tenantUserAllowed: true,
      // },
      // {
      //   key: 'orders',
      //   text: "generals.orders",
      //   route: "/orders/",
      //   icon: "mdi-clipboard-list-outline",
      //   id: 7,
      //   requireAuth: true,
      //   tenantUserAllowed: true,
      // },
      {
        key: 'admin_list',
        text: "generals.adminUsers",
        route: "/admin/list",
        icon: "mdi-account-circle-outline",
        id: 8,
        requireAuth: true,
        tenantUserAllowed: false,
      },
/*       {
        key: 'userProfile',
        text: "generals.userProfile",
        route: "user/",
        icon: "mdi-account-circle-outline",
        id: 7,
        requireAuth: true,
        tenantUserAllowed: true,
      },
 */      {
        // key: 'preference',
        // text: "generals.preferences",
        // route: null,
        // icon: "mdi-cog-outline",
        // id: 8,
        // requireAuth: true,
        // tenantUserAllowed: true,
      },
      {
        key: 'manageData',
        text: "tenants.manageData",
        route: '/tenant/master-data',
        icon: "mdi-database-sync-outline",
        id: 9,
        requireAuth: true,
        tenantUserAllowed: false,
      },
      {
        key: 'logout',
        text: "auth.logout.text",
        route: null,
        icon: "mdi-logout",
        onClick: "signOut",
        id: 10,
        requireAuth: true,
        tenantUserAllowed: true,
      },
    ],
  },
  getters: {
    menuItems: (state) => state.menu,
    DPRBaseUrl: (state) =>
      process.env.NODE_ENV == "development"
        ? `https://distribuye.herokuapp.com/`
        : state.distribuyeUrl,
  },
  mutations: {
    setDrawerState(state, payload) {
      state.drawer = payload;
    },
  },
  actions: {
    toggleDrawer({ commit }, payload) {
      commit("setDrawerState", payload);
    },
  },
};
export default Navigation;
