import axios from '@/plugins/axios'

const Locations = {
    all() {
        return axios({ requiresAuth: true }).get('locations');
    },
    show(id){
        return axios({ requiresAuth: false }).get(`locations/${id}`);
    },
    byTenant(id){
        return axios({ requiresAuth: true }).get(`locations/tenant/${id}`);
    },
    store(data) {
        return axios({ requiresAuth: true }).post('locations', data);
    },
}

export default Locations