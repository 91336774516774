import products from '../../api/products'

const Products = {
    state: {
        all: [],
        details: {}
    },
    getters: {
        products: state => state.all,
        currentProductDetails :state => state.details,
    },
    mutations: {
        setProducts(state, products) {
            state.all = products
        },
        setProductDetail(state, products) {
            state.details = products
        },
        decrementProductInventory(state, { id }) {
            const product = state.all.find(product => product.id === id)
            product.inventory--
        }
    },
    actions: {
        async getAllProducts({ commit }) {
            const response = await products.all();
            if (!response.data.data.length) return null;
            const { data } = response.data;
            let TempProducts = data.slice(0, 100);
            commit('setProducts', TempProducts);
            return TempProducts;
        },
        async getProductInfo({ commit }, id) {
            const {data} = await products.show(id);
            commit('setProductDetail', data.data);
            return data;
        }
    }
}

export default Products;