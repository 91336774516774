import moduleApi from "../../api/modules"

const Modules = {
    state: {
        modules: []
    },
    getters: {
       modules: (state) => state.modules
    },
    mutations: {
        setModules(state, data){
            state.modules = data;
        }
    },
    actions: {
        async fetchModules({commit}) {
            const {data} = await moduleApi.all();

            commit("setModules", data.data)

            return data;
        },

        async uploadFile({dispatch}, data) {
            const responseData = await moduleApi.uploadFile(data.tenantId, data);

            dispatch("fetchModules");

            return responseData.data;
        }
    },
}

export default Modules