import locationsApi from "../../api/locations";

const Locations = {
  state: {
    all: [],
    byTenant: []
  },
  getters: {
    locations: (state) => state.all,
    locationsByTenant: (state) => state.byTenant,
  },
  mutations: {
    setLocations(state, locations) {
      state.all = locations;
    },
    setLocationsByTenant(state, suppliers) {
      state.byTenant = suppliers.inventory;
    },
  },
  actions: {
    async getAllLocations({ commit }) {
      const response = await locationsApi.all();
      if (!response.data.data.length) return null;
      const { data } = response.data;
      commit("setLocations", data);
      return data;
    },
    async getLocationsByTenant({ commit }, tenantId) {
      const response = await locationsApi.byTenant(tenantId);
      const { data } = response.data;
      commit("setLocationsByTenant", data);
      return data;
    },
    async createLocation({ dispatch }, location) {
      const response = await locationsApi.store(location);
      const { data } = response.data;
      dispatch("getAllLocations");
      return data;
    },
  },
};
export default Locations;
