import axios from "@/plugins/axios";

const Tenant = {
  all() {
    return axios({ requiresAuth: true }).get("api/tenants");
  },
  show(id) {
    return axios({ requiresAuth: true }).get(`api/tenants/${id}`);
  },
  create(data) {
    const formData = new FormData();

		for (const key in data) {
			const value = data[key];
			formData.append(key, value);
		}
		return axios({ requiresAuth: true }).post("api/tenants", formData, {
			headers: {
				"Content-Type": "multipart/form-data",
			},
		});
  },

  update(data) {
    const formData = new FormData();
    formData.append("_method", 'PATCH');

    for (const key in data) {
      formData.append(key, data[key]);
    }


    return axios({ requiresAuth: true}).post(
      `api/tenants/${formData.get('id')}`,
        formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'enctype' : 'multipart/form-data'
          }
        }
    );
  },

  updateTenantApiKeys(data) {
    return axios({ requiresAuth: true, isFormData: false}).patch(
        `api/tenants/${data.id}/apiKeys`,
        data
    );
  },

  delete(id) {
    return axios({ requiresAuth: true }).delete(`api/tenants/${id}`);
  },
  allUsers(id) {
    return axios({ requiresAuth: true }).get(`api/tenants/${id}/users`);
  },
  storeUser(data) {
    return axios({ requiresAuth: true }).post("api/users", data);
  },
  updateUser(data) {
    return axios({ requiresAuth: true }).patch(`api/users/${data.id}`, data);
  },
  updateUserOnly(userData) {
    return axios({ requiresAuth: true }).patch(`api/users/${userData.id}/updateUserOnly`, userData);
  },
  storeDUser(data) {
    return axios({ requiresAuth: true }).post(`api/tenants/${data.tenantId}/users`, data);
  },
  createTenantModule(data) {
    return axios({ requiresAuth: true }).post(`api/tenants/${data.tenant_id}/modules`, data);
  },
  getModuleRoles(id){
    return axios({requiresAuth: true}).get(`api/tenants/${id}/roles`);
  },

  checkEmailExist(email) {
    return axios({requiresAuth: true}).get('api/user/emailAlreadyExist', { params: { email: email }});
  },

  checkTenantIdentificationExist(tenantIdentification) {
    return axios({requiresAuth: true}).get('api/tenant/tenantIdentificationAlreadyExist', { params: { tenantIdentification: tenantIdentification}});
  },
};

export default Tenant;
