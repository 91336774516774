<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon>
        <v-img v-if="avatar" :src="avatar" alt="User Avatar" />
        <v-icon large v-else> mdi-account-circle-outline </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-avatar>
            <v-img v-if="avatar" :src="avatar" alt="User Avatar" />
            <v-icon large v-else> mdi-account-circle-outline </v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title v-if="!!userData.username">{{
              userData.username
            }}</v-list-item-title>
            <v-list-item-title v-else>
              {{ $t("auth.login.newUserTitle") }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ userData.email }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      session: false,
      menu: false,
    };
  },
  computed: {
    ...mapGetters(["sessionData", "userData", "avatar"]),
  },
};
</script>

<style>
</style>