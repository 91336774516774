import tenantApi from "../../api/tenant";

const Products = {
  state: {
    tenantAll: [],
    tenantUsers: [],
    tenantEmailUsers: [],
    distribuyeUsers: [],
    moduleRoles: [],
    tenantCurrent: {},
    currentTenantUser: {}, //user info of the logged in tenant(this.tenantCurrent)
    hasTenantAdminRole: false,
    tenantFormData: {
      tenant_identification: "",
      domain: "",
      logo: "",
      name: "",
      ein: "",
      merchant_registration: "",
      mp_data: {
        isEnabled: false,
      },
      im_data: {
        isEnabled: false,
      },
      kyc_data: {
        isEnabled: false,
      },
      wms_data: {
        isEnabled: false,
      },
      payment_info: {
        account_name: '',
        bank_routing_number: '',
        checking_account_number: '',
        driver_issue: '',
        state: '',
      },
      billing_info: {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        country: '',
      },
      is_supplier: false,
      api_keys: {
        quickbooks: '',
        clover: ''
      }
    },
  },

  getters: {
    tenants: (state) => state.tenantAll,
    currentTenantUsers: (state) => state.tenantUsers,
    currentTenantEmailUsers: (state) => state.tenantEmailUsers,
    currentTenantDistribuyeUsers: (state) => state.distribuyeUsers,
    currentTenant: (state) => state.tenantCurrent,
    tenantFormData: state => state.tenantFormData,
    billingInfoFrm: state => state.tenantFormData.billing_info,
    paymentInfoFrm: state => state.tenantFormData.payment_info,
    modulesMpData: state => state.tenantFormData.mp_data,
    modulesImData: state => state.tenantFormData.im_data,
    modulesWmsData: state => state.tenantFormData.wms_data,
    modulesKycData: state => state.tenantFormData.kyc_data,
    moduleRoles: state => state.moduleRoles,
    getCurrentTenantUser: state => state.currentTenantUser,
    getHasTenantAdminRole: state => state.hasTenantAdminRole,
  },

  mutations: {
    currentTenant(state, data){
      state.tenantCurrent = data.data
    },

    setBillingInfoFrmData(state, data){
      state.tenantFormData.billing_info[data.key] = data.data;
    },

    setPaymentInfoFrmData(state, data){
      state.tenantFormData.payment_info[data.key] = data.data;
    },

    setTenantsFrmData(state, data) {
      state.tenantFormData[data.key] = data.data;
    },

    setTenants(state, data) {
      state.tenantAll = data;
    },

    setTenantUsers(state, data) {
      state.distribuyeUsers = data;
      state.tenantUsers = data;
    },

    setTenantEmailUsers(state, data) {
      state.tenantEmailUsers = data;
    },

    setCurrentTenant(state, data) {
      state.tenantCurrent = data;

      const inventoryId = parseInt(process.env.VUE_APP_INVENTORY_ID);
      const wmsId = parseInt(process.env.VUE_APP_WMS_ID);
      const marketplaceId = parseInt(process.env.VUE_APP_MARKETPLACE_ID);

      // Get Active Modules
      if (data.modules?.length) {
        data.modules.forEach(mod =>{
          //Check Module Enabled
          if (mod.pivot.status === "Enabled") {
            switch(mod.id){
              case inventoryId:
                state.tenantFormData.im_data.isEnabled = true;
                break;
              case wmsId:
                state.tenantFormData.wms_data.isEnabled = true;
                break;
              case marketplaceId:
                state.tenantFormData.mp_data.isEnabled = true;
                break;
              default:
                console.log("Module not found: ", mod.id);
            }
          }
        });
      } else {
        state.tenantFormData.im_data.isEnabled = false;
        state.tenantFormData.wms_data.isEnabled = false;
        state.tenantFormData.mp_data.isEnabled = false;
      }
    },

    setCurrentTenantUser(state, data) {
      state.currentTenantUser = data;
    },

    setHasTenantAdminRole(state, data) {
      state.hasTenantAdminRole = data;
    },

    setTenantFrmDataAll(state, data){
      state.tenantFormData = data;
    },

    setCurrentTenantFormData(state, data){
      state.tenantFormData.tenant_identification = data.tenant_identification;
      state.tenantFormData.domain = data.domain;
      state.tenantFormData.name = data.name;
      state.tenantFormData.ein = data.ein;
      state.tenantFormData.merchant_registration = data.merchant_registration;
    },

    clearTenantFrmData(state) {
      state.tenantFormData = {
        tenant_identification: "",
        domain: "",
        logo: "",
        name: "",
        ein: "",
        merchant_registration: "",
        mp_data: {
          isEnabled: false,
        },
        im_data: {
          isEnabled: false,
        },
        wms_data: {
          isEnabled: false,
        },
        kyc_data: {
          isEnabled: false,
        },
        payment_info: {
          account_name: '',
          bank_routing_number: '',
          checking_account_number: '',
          driver_issue: '',
          state: '',
        },
        billing_info: {
          address: '',
          city: '',
          state: '',
          zipcode: '',
          country: '',
        },
        is_supplier: false,
      };
    },

    clearTenantUsers(state){
      state.tenantUsers = [];
    },

    setModuleRoles(state, data) {
      state.moduleRoles = data
    }
  },

  actions: {
    async clearTenantForm({ commit }) {
      commit("clearTenantFrmData");
    },

    async createTenant({ dispatch }, tenant) {
      const { data } = await tenantApi.create(tenant);
      dispatch("getAllTenants");

      return data;
    },

    async fetchCurrentTenant({commit}, tenantId){
      const {data} = await tenantApi.show(tenantId);
      commit("setCurrentTenant", data.data)
    },

    async createTenantModule({ dispatch }, tenant) {
      const { data } = await tenantApi.createTenantModule(tenant);
      dispatch("getAllTenants");

      return data;
    },

    async updateTenantApiKeys({ dispatch }, apiKeys) {
      if (!apiKeys.id) { //tenant ID
        return null;
      }

      let tmpTenantaApi = Object.assign({}, apiKeys);

      if (apiKeys.billing_info) {
        tmpTenantaApi.api_modules = JSON.stringify(apiKeys.api_modules);
      }


      const { data } = await tenantApi.updateTenantApiKeys(tmpTenantaApi);
      dispatch("getAllTenants", data.data);

      return data;
    },

    async updateTenant({ dispatch }, tenant) {
      if (!tenant.id) {
        return null;
      }

      let tmpTenant = Object.assign({}, tenant);

      if (tenant.billing_info) {
        tmpTenant.billing_info = JSON.stringify(tenant.billing_info);
      }

      if (tenant.payment_info) {
        tmpTenant.payment_info = JSON.stringify(tenant.payment_info);
      }

      if (tenant.mp_data) {
        tmpTenant.mp_data = JSON.stringify(tenant.mp_data);
      }

      if (tenant.im_data) {
        tmpTenant.im_data = JSON.stringify(tenant.im_data);
      }

      if (tenant.wms_data) {
        tmpTenant.wms_data = JSON.stringify(tenant.wms_data);
      }

      if (tenant.kyc_data) {
        tmpTenant.kyc_data = JSON.stringify(tenant.kyc_data);
      }
      
      const { data } = await tenantApi.update(tmpTenant);
      dispatch("getAllTenants", data.data);

      return data;
    },

    async deleteTenant({ dispatch }, tenant_data) {
      const response = await tenantApi.delete(tenant_data.id);

      if (!response.data.data.id) return null;

      const { data } = response.data;
      dispatch("getAllTenants");

      return data;
    },

    async getAllTenants({ commit , rootState}) {
       if (rootState.user.current_user_type.key == 'master_admin') {
        const response = await tenantApi.all();

        if (!response.data.data.length) return null;

        const { data } = response.data;
        commit("setTenants", data);

        return data;
      }

        return [];

    },

    async getTenantsById({ commit }, tenant_data) {
      let tenant_id = 0

      if (tenant_data.supplier_id) {
        tenant_id = tenant_data.supplier_id
      } else {
        tenant_id = tenant_data.tenant_id
      }

      const response = await tenantApi.show(tenant_id);

      if (!response.data.data.id) return null;

      const { data } = response.data;
      const apiKeys = {}

      data.modules.forEach(module => {
        apiKeys[module.slug] = {}
        module.api_keys.forEach(apiKey => {
          apiKeys[module.slug][apiKey["service_name"]] = apiKey["api_key"]
        })
      })

      data.modules?.api_keys?.forEach(element => {
        apiKeys[element["service_name"]] = element["api_key"]
      })

      data["api_keys"] = apiKeys
      commit("setCurrentTenant", data);

      return data;
    },

    async getTenantsUsers({ commit }, tenant_data) {
      const response = await tenantApi.allUsers(
        tenant_data.tenant_id || tenant_data.id
      );

      const { data } = response.data;
      commit("setTenantUsers", data);

      const moduleDataResponse = await tenantApi.getModuleRoles(tenant_data.tenant_id || tenant_data.id);
      const moduleData = moduleDataResponse.data.data;

      const roles = moduleData.roles.filter(r => r.slug !== 'ROLE_ADMIN');

      const inventoryData = moduleData.modules_roles.find(mr => mr.id == process.env.VUE_APP_INVENTORY_ID);
      let inventoryRoles = [];

      if (inventoryData != null) {
        inventoryRoles = inventoryData.roles;
      }

      const wmsData = moduleData.modules_roles.find(mr => mr.id == process.env.VUE_APP_WMS_ID);
      let wmsRoles = [];

      if (wmsData != null) {
        wmsRoles = wmsData.roles;
      }

      const rolesData = [
        {
          id: 0,
          roles: roles
        },
        {
          id: process.env.VUE_APP_WMS_ID,
          roles: wmsRoles
        },
        {
          id: process.env.VUE_APP_INVENTORY_ID,
          roles: inventoryRoles,
        },
      ]

      commit("setModuleRoles", rolesData);

      return data;
    },

    async createTenantsUser({ dispatch }, store_data) {
      const { data } = await tenantApi.storeUser(store_data);
      dispatch("getTenantsUsers", store_data);

      return data;
    },

    async createDistribuyeUser({ dispatch }, store_data) {
      const { data } = await tenantApi.storeDUser(store_data);
      dispatch("getTenantsUsers", store_data.tenant);

      return data;
    },

    async updateTenantsUser({ dispatch, state }, updated_data) {
      const { data } = await tenantApi.updateUser(updated_data);
      dispatch("getTenantsUsers", state.tenantCurrent);

      return data;
    },

    async updateUserOnly({ dispatch, state }, updated_data) {
      const { data } = await tenantApi.updateUserOnly(updated_data);
      dispatch("getTenantsUsers", state.tenantCurrent);

      return data;
    }
  },
};

export default Products;
