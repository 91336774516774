import firebase from 'firebase/app'
import 'firebase/firebase-messaging'
//import "firebase/analytics";

const firebaseSettings = {
  apiKey: "AIzaSyCtmDebPkBVdipPkEJbMeFGK13jdu02c3E",
  authDomain: "allec-lite-pr.firebaseapp.com",
  projectId: "allec-lite-pr",
  storageBucket: "allec-lite-pr.appspot.com",
  messagingSenderId: "147834082336",
  appId: "1:147834082336:web:80b40468d4badcf5e95845",
  measurementId: "G-CRQEVHLGPX"
};

  firebase.initializeApp(firebaseSettings)

  export default firebase/* .messaging() */