<template>
  <v-overlay :value="overlay" class="text-center">
    <v-progress-circular
      indeterminate
      size="63"
      :color="color"
    ></v-progress-circular>
    <p class="text-body mt-3">{{ text }}</p>
  </v-overlay>
</template>

<script>
export default {
  data: () => ({
    persistent: false,
    overlay: false,
    text: "",
    color: "white",
    timeout: 10000,
  }),
  watch: {
    /** Prevenir bloqueos */
    overlay(val) {
      val &&
        !this.persistent &&
        setTimeout(() => {
          this.overlay = false;
        }, this.timeout);
    },
  },
  created() {
    const self = this;
    self.$store.subscribe((mutation, state) => {
      if (mutation.type === "closeOverlay") {
        self.overlay = false;
      }
      if (
        mutation.type === "setNotificationProps" &&
        state.notifications.commonProps.type == 3
      ) {
        self.text = state.notifications.commonProps.text
          ? state.notifications.commonProps.text
          : self.$t(state.notifications.commonProps.lngKey);
        self.color = state.notifications.commonProps.color
          ? state.notifications.commonProps.color
          : self.color;
        self.timeout = state.notifications.commonProps.timeout
          ? state.notifications.commonProps.timeout
          : self.timeout;
        self.persistent = state.notifications.commonProps.persistent
          ? state.notifications.commonProps.persistent
          : self.persistent;
        self.overlay = true;
      }
    });
  },
};
</script>

<style>
</style>