import axios from '@/plugins/axios'

const Invoices = {
    all() {
        return axios({ requiresAuth: true }).get('invoices');
    },
    show(id){
        return axios({ requiresAuth: false }).get(`invoices/${id}`);
    },
    somethingLike(name){
        return axios({ requiresAuth: false }).get(`invoices/${name}`);
    }
}

export default Invoices