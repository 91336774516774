import suppliersApi from "../../api/suppliers";

const Suppliers = {
  state: {
    all: [],
    byTenant: [],
    supplierFormData: {
      entity_name: null,
      phone_number: null,
      point_of_contact: null,
      email: null,
      description: null,
      postal_address: null,
      physical_address: null,
      postal_city: null,
      physical_city: null,
      postal_zipcode: null,
      physical_zipcode: null,
      image_path: null,
      is_active: null,
    }
  },
  getters: {
    suppliers: (state) => state.all,
    suppliersByTenant: (state) => state.byTenant,
    supplierFormData: state => state.supplierFormData,
  },
  mutations: {
    setSuppliers(state, suppliers) {
      state.all = suppliers;
    },
    setSuppliersByTenant(state, suppliers) {
      state.byTenant = suppliers.inventory;
    },
    setSupplierFormData(state, data) {
      state.supplierFormData[data.key] = data.data;
    },
    setSupplierFormDataAll(state, data){
      state.supplierFormData = data;
    },

  },
  actions: {
    async createSupplier(_, supplier) {
      const { data } = await suppliersApi.create(supplier);
      return data;
    },
    async updateSupplier(_, supplier) {
      if(!supplier.id)return null;
      // let updatedSupplier = JSON.stringify(supplier);
      
      const { data } = await suppliersApi.update(supplier);
      // dispatch("getAllTenants", data.data);
      return data;
    },
    async getAllSuppliers({ commit }) {
      const response = await suppliersApi.all();
      const { data } = response.data;
      commit("setSuppliers", data);
      return data;
    },
    async getSuppliersByTenant({ commit }, tenantId) {
      const response = await suppliersApi.byTenant(tenantId);
      const { data } = response.data;
      commit("setSuppliersByTenant", data);
      return data;
    },
    async getSupplierById(_, tenantId) {
      const response = await suppliersApi.show(tenantId);
      const { data } = response.data;
      return data;
    }
  },
};
export default Suppliers;
