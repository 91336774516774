import api from "@/api/user";

const User = {
  state: {
    current_user_type: {},
    types: [
      {
        id: 1,
        name: "Master Admin",
        key: "master_admin",
      },
      {
        id: 2,
        name: "Tenant User",
        key: "tenant_user",
      },
      {
        id: 3,
        name: "Tenant Admin",
        key: "tenant_admin",
      },
      // {
      //   id: 4,
      //   name: "Distribuye User",
      //   key: "distribuye_user",
      // },
    ],
    user: {
      username: null,
      email: null,
    },
    users_profile: {
      avatar: null,
    },
    adminUsers: [],
  },
  getters: {
    userData: (state) => state.user,
    userProfile: (state) => state.users_profile,
    avatar: (state) => state.users_profile.avatar,
    userTypes: (state) => state.types,
    currentUserType: (state) => state.current_user_type,
    adminUsers: (state) => state.adminUsers,
  },
  mutations: {
    setCurrentUserType(state, data) {
      state.current_user_type = data;
    },
    setUserData(state, data) {
      state.user = data;
    },
    setUserProfile(state, data) {
      state.users_profile = data;
    },
    clearUserData(state) {
      state.user = {
        users_profile: {
          username: null,
          email: null,
        },
      };
    },
    setAdminUsers(state, data) {
      state.adminUsers = data;
    },
    clearAdminUsers(state){
      state.adminUsers = [];
    },
  },
  actions: {
    async fetchUserData({ commit, dispatch }) {
      const { data } = await api.get();
      commit("setUserData", data);
      if (data.id) {
        dispatch("showNotification", {
          type: 3,
          lngKey: "generals.gettingPreferences",
          indeterminate: true,
        });
        await dispatch("fetchUserProfile");
        await dispatch("getPreferences");
        await dispatch("closeNotifications");
      }
      return data;
    },
    async fetchUserProfile({ commit }) {
      const { data } = await api.getProfile();
      commit("setUserProfile", data.data[0]);
      return data;
    },
    async createAdminUser({ dispatch }, user) {
      const { data } = await api.storeAdminUser(user);
      dispatch("getAllAdminUsers");
      return data;
    },
    async getAllAdminUsers({ commit }) {
      const response = await api.getAllAdminUsers();
      if (!response.data.data.length) return null;
      const { data } = response.data;

      commit("setAdminUsers", data);
      return data;
    },
  },
};
export default User;
