/* layouts */
import MainLayout from "@/layouts/MainLayout";
import AuthLayout from "@/layouts/AuthLayout";
import  store  from '../store/store';

/**
 * Meta params: {
 *  layout: select layout to use,
 *  showProgressBar: state of NProgress in navigation,
 *  requiresAuth: set auth level to validate in route
 * }
 */

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    meta: { layout: MainLayout, requiresAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: () => import("@/views/auth/SignIn"),
    meta: { layout: AuthLayout },
  },
  {
    path: "/singup",
    name: "Singup",
    component: () => import("@/views/auth/SingUp"),
    meta: { layout: AuthLayout },
  },
  {
    path: "/auth/callback",
    name: "Callback",
    component: () => import("@/views/auth/Callback"),
    meta: {layout: AuthLayout},
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/user/"),
    meta: { requiresAuth: true },
    redirect: { path: "/user/profile" },
    children: [
      {
        name: "user profile",
        path: "profile",
        component: () => import("@/views/user/Profile"),
        meta: { layout: MainLayout },
      },
    ],
  },
  {
    path: "/product",
    name: "Product",
    component: () => import("@/views/product"),
    children: [
      {
        name: "details",
        path: "details/:name/:id",
        component: () => import("@/views/product/Details"),
        meta: { layout: MainLayout },
      },
    ],
  },
  {
    path: "/tenant",
    name: "Tenant",
    meta: { requiresAuth: true },
    redirect: { path: "/tenant/menu" },
    component: () => import("@/views/tenant"),
    children: [
      {
        name: "Manage tenants",
        path: "manage/:tenant_id?",
        component: () => import("@/views/tenant/Manage"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let urlTenantId = to.params.tenant_id;
          let currentTenantId = store.getters.currentTenant.id;
          let userType = store.getters.currentUserType.key;

          if (urlTenantId != currentTenantId && (userType !== "master_admin" || userType === 'tenant_user')) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
      {
        name: "Tenants menu",
        path: "menu",
        component: () => import("@/views/tenant/Menu"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let userType = store.getters.currentUserType.key;

          if (userType === 'tenant_user') {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
      {
        name: "Tenants list",
        path: "list",
        component: () => import("@/views/tenant/List"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let userType = store.getters.currentUserType.key;

          if (userType === 'tenant_user') {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
      {
        name: "Tenants master data",
        path: "master-data",
        component: () => import("@/views/tenant/MasterData"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let inactive = store.getters.allSubscriptionsAreInactive;
          let userType = store.getters.currentUserType.key;

          if (inactive && (userType !== "master_admin" || userType === 'tenant_user')) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/company",
    name: "Company",
    meta: { requiresAuth: true },
    redirect: { path: "/company/list" },
    component: () => import("@/views/company"),
    children: [
      {
        name: "All Companies",
        path: "list",
        component: () => import("@/views/company/List"),
        meta: { layout: MainLayout, requiresAuth: true },
      },
      {
        name: "Create Company",
        path: "create",
        component: () => import("@/views/company/Create"),
        meta: { layout: MainLayout, requiresAuth: true },
      },
    ],
  },
  {
    path: "/location",
    name: "Location",
    meta: { requiresAuth: true },
    redirect: { path: "/location/list" },
    component: () => import("@/views/location"),
    children: [
      {
        name: "Locations",
        path: "list",
        component: () => import("@/views/location/List"),
        meta: { layout: MainLayout, requiresAuth: true },
      },
      {
        name: "Manage Locations",
        path: "manage/:location_id?",
        component: () => import("@/views/location/Manage"),
        meta: { layout: MainLayout, requiresAuth: true },
      },
    ],
  },
  {
    path: "/invoice",
    name: "Invoice",
    meta: { requiresAuth: true },
    redirect: { path: "/invoice/list" },
    component: () => import("@/views/invoice"),
    children: [
      {
        name: "Invoices",
        path: "list",
        component: () => import("@/views/invoice/List"),
        meta: { layout: MainLayout, requiresAuth: true },
      },
    ],
  },
  {
    path: "/supplier",
    name: "Supplier",
    meta: { requiresAuth: true },
    redirect: { path: "/supplier/list" },
    component: () => import("@/views/invoice"),
    children: [
      {
        name: "Suppliers List",
        path: "list",
        component: () => import("@/views/supplier/List"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let inactive = store.getters.allSubscriptionsAreInactive;
          let userType = store.getters.currentUserType.key;

          if (inactive && (userType !== "master_admin" || userType === 'tenant_user')) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
      {
        name: "Create Supplier",
        path: "create",
        component: () => import("@/views/supplier/Create"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let inactive = store.getters.allSubscriptionsAreInactive;
          let userType = store.getters.currentUserType.key;

          if (inactive && (userType !== "master_admin" || userType === 'tenant_user')) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
      {
        name: "Manage Suppliers",
        path: "manage/:tenant_id?",
        component: () => import("@/views/supplier/Manage"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let inactive = store.getters.allSubscriptionsAreInactive;
          let userType = store.getters.currentUserType.key;

          if (inactive && (userType !== "master_admin" || userType === 'tenant_user')) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/orders",
    name: "Orders",
    meta: { requiresAuth: true },
    redirect: { path: "/orders/list" },
    component: () => import("@/views/invoice"),
    children: [
      {
        name: "Orders List",
        path: "list",
        component: () => import("@/views/orders/List"),
        meta: { layout: MainLayout, requiresAuth: true },
      },
    ],
  },
  {
    path: "/admin",
    name: "Admin",
    meta: { requiresAuth: true },
    redirect: { path: "/admin/list" },
    component: () => import("@/views/admin"),
    children: [
      {
        name: "Admin List",
        path: "list",
        component: () => import("@/views/admin/List"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let inactive = store.getters.allSubscriptionsAreInactive;
          let userType = store.getters.currentUserType.key;

          if (inactive && (userType !== "master_admin" || userType === 'tenant_user')) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
      {
        name: "Admin Create",
        path: "create",
        component: () => import("@/components/admin/AdminForm"),
        meta: { layout: MainLayout, requiresAuth: true },
        beforeEnter(to, from, next) {
          let inactive = store.getters.allSubscriptionsAreInactive;
          let userType = store.getters.currentUserType.key;

          if (inactive && (userType !== "master_admin" || userType === 'tenant_user')) {
            next({ name: "Home" });
          } else {
            next();
          }
        },
      },
    ],
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/common/404"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

export default routes;
