import axios from '@/plugins/axios'

const Preferences = {
    get() {
        return axios().get('userpreference');
    },
    store(data){
        return axios({ requiresAuth: true }).post('userpreference', data); 
    }
}

export default Preferences