import Vue from "vue";
import App from "./App.vue";
import NProgress from "vue-nprogress";
import router from "./router";
import store from "./store/store";
import vuetify from "./plugins/vuetify";
import i18n from "./i18n";
import "@/css/main.css";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import { sync } from "vuex-router-sync";
import ZoomOnHover from "vue-zoom-on-hover";
import VueMask from "v-mask"; //https://www.npmjs.com/package/v-mask
import "vuetify/dist/vuetify.min.css";

/* Firebase PLugin */
import firebase from "@/data/firebase";

import "./filters";

const unsync = sync(store, router);

Vue.use(VueSweetalert2);
Vue.use(ZoomOnHover);
Vue.use(VueMask);

const options = {
  latencyThreshold: 200, // Number of ms before progressbar starts showing, default: 100,
  router: true, // Show progressbar when navigating routes, default: true
  http: false, // Show progressbar when doing Vue.http, default: true
};
Vue.use(NProgress, options);

const nprogress = new NProgress();
nprogress.configure({ showSpinner: false });

/* Mixins */
import commons from "@/mixins/commons";

import "./registerServiceWorker";

Vue.mixin(commons);

Vue.config.productionTip = false;

/* Only for dev purposes */
if (process.env.NODE_ENV === "development") {
  const messaging = firebase.messaging();
  Vue.prototype.$messaging = messaging;
} else {
  Vue.prototype.$messaging = false;
}

new Vue({
  nprogress,
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

unsync();
