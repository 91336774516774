import axios from "@/plugins/axios";

const Stripe = {
    getStripeCheckoutSession(data) {
        return axios({ requiresAuth: true }).get("api/stripe/getCheckoutSession", {
            params: {
                items: data.items,
                tenantId: data.tenant_id,
                successUrl: data.successUrl,
                cancelUrl: data.cancelUrl
            }
        });
    },

    getTenantActiveSubscriptions(data) {
        return axios({ requiresAuth: true }).get("api/stripe/getTenantActiveSubscriptions", {
            params: {
                tenantId: data.tenant_id
            }
        });
    },

    getBillingPortalUrl(params) {
        return axios({requiresAuth: true}).get('api/stripe/getBillingPortalUrl', {
            params: { tenantId: params.tenantId,
                returnToUrl: params.returnToUrl }
        });
    },

    getSubscriptionsData() {
        return axios({ requiresAuth: true }).get("api/stripe/getSubscriptionsData");
    }
}
export default Stripe;