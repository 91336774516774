import axios from '@/plugins/axios'

const Auth = {
    login(data) {
        // email: "",
        //     password: "",
        //     remember: false,

        let bodyFormData = new FormData();

        bodyFormData.append('grant_type', 'authorization_code');
        bodyFormData.append('client_id', process.env.VUE_APP_CLIENT_ID);
        bodyFormData.append('scope', '*');
        bodyFormData.append('redirect_uri', process.env.VUE_APP_REDIRECT_URI + "/auth/callback");
        bodyFormData.append('code_verifier', data.codeVerifier);
        bodyFormData.append('code', data.code);

        return axios({ requiresAuth: false }).post('oauth/token', bodyFormData);
    },
    logout() {
        return axios({ requiresAuth: true }).get('auth/logout');
    },
    register(data) {
        return axios({ requiresAuth: false }).post('auth/register', data);
    }
}

export default Auth