<template>
  <v-autocomplete
    allow-overflow
    clearable
    outlined
    v-model="model"
    :loading="isLoading"
    :items="items"
    :search-input.sync="search"
    item-text="product_name"
    cache-items
    flat
    hide-no-data
    hide-details
    :placeholder="$t('generals.searchPlaceholder')"
    rounded
    dense
    return-object
    eager
    :menu-props="{
      closeOnContentClick: true,
    }"
  >
    <template v-slot:item="data">
      <v-list-item
        :key="data.item.title"
        v-bind="data.attrs"
        v-bind:on="data.on"
        :to="`/product/details/${data.item.product_name}/${data.item.product_id}`"
      >
        <v-list-item-avatar rounded="sm" size="3rem">
          <v-img contain :src="data.item.product_pic" eager></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title
            v-html="data.item.product_name"
          ></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </template>

    <template v-slot:progress>
      <v-progress-circular
        indeterminate
        color="primary"
        size="27"
        class="my-auto"
        :width="3"
      ></v-progress-circular>
    </template>
  </v-autocomplete>
</template>
<script>
import prodApi from "@/api/products";
export default {
  data: () => ({
    descriptionLimit: 90,
    entries: [],
    isLoading: false,
    model: null,
    search: null,
  }),

  computed: {
    items() {
      return this.entries.map((entry) => {
        const product_name =
          entry.product_name.length > this.descriptionLimit
            ? entry.product_name.slice(0, this.descriptionLimit) + "..."
            : entry.product_name;

        return Object.assign({}, entry, { product_name });
      });
    },
  },

  watch: {
    search(val) {
      const self = this;
      if (!val || val.length < 3) return;
      // Items have already been loaded
      if (self.items.length > 0) return;

      // Items have already been requested
      if (self.isLoading) return;

      self.isLoading = true;

      // Lazily load input items
      prodApi
        .somethingLike(val)
        .then((suss) => {
          const { data } = suss.data;
          self.entries = data;
        })
        .catch((err) => {
            this.$swal.fire({
                timer: 4000,
                icon: "error",
                title: "Error Loading the Items",
                text: err,
                toast: true,
                position: "bottom-end",
                timerProgressBar: true,
                showConfirmButton: false,
                showCloseButton: true,
            });
        })
        .finally(() => (self.isLoading = false));
    },
  },
};
</script>