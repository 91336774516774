<template>
  <v-app id="app">
    <notifications />
    <nprogress-container></nprogress-container>
    <component :is="this.$route.meta.layout || 'div'">
      <router-view />
    </component>
  </v-app>
</template>

<script>
import NprogressContainer from "vue-nprogress/src/NprogressContainer";
import Notifications from "./components/notifications/";
export default {
  name: "App",
  components: {
    NprogressContainer,
    Notifications,
  },
  methods: {
    initializeFirebase() {
      const self = this;
      self.$messaging
        .getToken({
          vapidKey:
            "BIO00qgRDcx5VDVkboFPuGa0FOotk_injjld6olRx09ZpdqbLm9ehXjbAiBgHZ5RIUV7QbU_QC08KelF14FFx1M",
        })
        .then(async (currentToken) => {
          self.$messaging.onMessage((payload) => {
            if (payload.notification) {
              self.$store.commit("addNotification", payload.notification);
              self.$store.commit("incrementNotification");
              self.$store.dispatch("showNotification", {
                type: 1,
                text: payload.notification.body,
              });
            }
          });
          if (currentToken) {
            //console.log("client token", currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    },
  },
  mounted() {
    const self = this;
    if(self.$messaging){
      self.initializeFirebase();
    }
  },
};
</script>
