<template>
  <v-navigation-drawer app v-model="drawer">
    <v-list nav dense>
      <v-list-item to="/">
        <v-list-item-icon>
          <v-icon>mdi-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title> Home</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-show="!isLoggedIn"
        v-for="(item, i) in signUpItems"
        :key="`${item.id}_${i}`"
        :to="item.route"
        @click="onClick(item.onClick)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item
        v-for="(item, i) in loggedItems"
        :key="`${item.id}_${i}`"
        :to="item.route"
        @click="onClick(item.onClick)"
      >
        <v-list-item-icon>
          <v-icon v-text="item.icon"></v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title v-text="$t(item.text)"></v-list-item-title>
        </v-list-item-content>

        <v-badge
          :value="getContent(item.counter)"
          inline
          v-if="item.actionCounter"
          overlap
          color="primary"
          :content="getContent(item.counter)"
        ></v-badge>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      group: null,
    };
  },
  computed: {
    ...mapGetters(["isLoggedIn", "menuItems", "currentUserType"]),
    loggedItems() {
      const self = this;
      const currentUserTypeKey = self.currentUserType.key;
      /** 
       * Find items with Auth privileges 
       * */
      let loggeItems = self.menuItems.filter((item) => {
        return item.requireAuth === true
      });
      /**
       * Check If tenant and remove menu
       * items with privileges
       */
      if (currentUserTypeKey === "tenant_user") {
        loggeItems = self.menuItems.filter((item) => {
          return item.tenantUserAllowed === true;
        });
      }
      return loggeItems;
    },
    signUpItems() {
      const self = this;
      let signUpItems = self.menuItems.filter((item) => {
        return item.requireAuth === false;
      });
      return signUpItems;
    },
    drawer: {
      get() {
        return this.$store.state.navigation.drawer;
      },
      set(val) {
        this.$store.dispatch("toggleDrawer", val);
      },
    },
  },
  methods: {
    ...mapActions(["signOut", "checkMenuPermissions"]),
    onClick(function_name) {
      if (!function_name) return;
      const self = this;
      self.menu = false;
      self[function_name]();
      if (function_name == "signOut") {
        setTimeout(() => {
          self.$router.push("/signin");
        }, 500);
      }
    },
    getContent(key) {
      return this[key];
    },
    checkMenuPermissions(item = null) {
      return item;
    },
  },
};
</script>