import companiesApi from "../../api/companies";

const Companies = {
  state: {
    all: [],
  },
  getters: {
    companies: (state) => state.all,
  },
  mutations: {
    setCompanies(state, companies) {
      state.all = companies;
    },
  },
  actions: {
    async getAllCompanies({ commit }) {
      const response = await companiesApi.all();
      if (!response.data.data.length) return null;
      const { data } = response.data;
      commit("setCompanies", data);
      return data;
    },
    async getCompaniesByUserId({ commit }, id) {
      const response = await companiesApi.byUserId(id);
      if (!response.data.data.length) return null;
      const { data } = response.data;
      commit("setCompanies", data);
      return data;
    },
    async getCompaniesByTenantId({ commit }, id) {
      const response = await companiesApi.byTenantId(id);
      if (!response.data.data.length) return null;
      const { data } = response.data;
      commit("setCompanies", data);
      return data;
    },
  },
};
export default Companies;
