<template>
  <v-app id="main_layout">
    <main-navbar />
    <drawer v-if="$vuetify.breakpoint.mobile"></drawer>
    <v-main>
      <transition name="page" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
import MainNavbar from "@/components/common/Navbar";
import { mapActions } from "vuex";
import Drawer from "@/components/common/Drawer.vue";
export default {
  name: "MainLayout",
  components: {
    MainNavbar,
    Drawer,
  },
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getAllProducts"]),
  },
  created() {
    //this.getAllProducts();
  },
};
</script>