import invoicesApi from '../../api/invoices'

const Invoices = {
    state: {
      all: [],
    },
    getters: {
      invoices: state => state.all,
    },
    mutations: {
      setInvoices(state, invoices) {
        state.all = invoices
    },
    },
    actions: {
      async getAllInvoices({ commit }) {
        const response = await invoicesApi.all();
        const data  = response.data.data.length? response.data.data : response.data.data.data;
        commit('setInvoices', data);
        return data;
    },
    },
  };
  export default Invoices;