import axios from "axios";
export default ({
  requiresAuth = false,
  isAPI = true,
} = {}) => {
  let apiText = isAPI ? "/" : "";
  const options = {
    // timeout: 50000,
    baseURL:process.env.VUE_APP_BASE_API_URL + `${apiText}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin":"*",
      //"X-Requested-With": "XMLHttpRequest",
    },
  };
  const instance = axios.create(options);

  instance.defaults.timeout = 50000;
  
  const session = localStorage.getItem("session")
    ? JSON.parse(localStorage.getItem("session"))
    : {};

  instance.interceptors.request.use(
    (config) => {
      //config.data.api = true
      /* Check if route need Auth token */
      // console.log("Sesion: ", session, session.access_token);

      if (session && session.access_token && requiresAuth) {
        config.headers.common["Authorization"] = `Bearer ${session.access_token}`;
      }

      /* Check if user from tenant */
      if (
        session &&
        session.access_token &&
        session.tenant &&
        config.url != "api/auth/login"
      ) {
        config.headers.common["X-Tenant"] = session.tenant.id;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      if (response.status === 200 || response.status === 201) {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    },
    (error) => {
      //No internet connection
      console.log(error);
      if (
        error.message &&
        !error.response &&
        error.message === "Network Error"
      ) {
        return Promise.reject(error.message);
      }
      if (error.response.status) {
        switch (error.response.status) {
          case 400:
            console.info("400: ", error);
            break;
          case 401:
            console.info("401: ", error);
            break;
          case 403:
            console.info("403: ", error);
            break;
          case 404:
            console.info("404: ", error);
            break;
          case 502:
            console.info("502: ", error);
            break;
        }
        return Promise.reject(error.response);
      }
    }
  );
  return instance;
};
