import Vue from 'vue'
import Vuex from 'vuex'

/* Modules */
import auth from './modules/auth';
import companies from './modules/companies';
import invoices from './modules/invoices';
import locations from './modules/locations';
import modules from './modules/modules';
import navigation from './modules/navigation';
import notifications from './modules/notifications';
import orders from './modules/orders';
import preferences from './modules/preferences';
import products from './modules/products';
import roles from './modules/roles';
import suppliers from './modules/suppliers';
import tenant from './modules/tenant';
import user from './modules/user';
import stripe from './modules/stripe'


Vue.use(Vuex)

const store = new Vuex.Store({
    state: {

    },
    getters: {

    },
    mutations: {

    },
    actions: {
        showSubscriptionMessage(state, data) {
            //after the subscription is completed the user is redirected and
            //the message of success or fail is shown
            if (data.route.query.subscribed == 'true') {
                data.swal.fire({
                    timer: 8000,
                    icon: "success",
                    title: "Subscription created Successfully",
                    toast: true,
                    position: "bottom-end",
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                });

                //removes the 'subscribed' query parameter
                const query = Object.assign({}, data.route.query);
                delete query.subscribed;
                data.router.replace({ query: query }).catch(() => { });
            } else if (data.route.query.subscribed == 'false') {
                data.swal.fire({
                    timer: 8000,
                    icon: "warning",
                    title: "The subscription was canceled",
                    toast: true,
                    position: "bottom-end",
                    timerProgressBar: true,
                    showConfirmButton: false,
                    showCloseButton: true,
                });

                //removes the 'subscribed' query parameter
                const query = Object.assign({}, data.route.query);
                delete query.subscribed;
                data.router.replace({ query }).catch(() => { });
            }
        }
    },
    modules: {
        auth: auth,
        companies: companies,
        invoices: invoices,
        locations: locations,
        modules: modules,
        navigation: navigation,
        notifications: notifications,
        orders: orders,
        preferences: preferences,
        products: products,
        roles: roles,
        suppliers: suppliers,
        tenant: tenant,
        user: user,
        stripe: stripe,
    }
});

export default store