<template>
  <v-snackbar
    :centered="notificationState.position === 'center' ? true : false"
    :bottom="notificationState.position === 'bottom' ? true : false"
    :top="notificationState.position === 'top' ? true : false"
    app
    transition="scale-transition"
    right
    :value="show"
    :color="notificationState.color"
    @input="onClose"
  >
    <v-icon
      dark
      left
      v-if="notificationState.icon"
      v-html="notificationState.icon"
    >
    </v-icon>
    {{ bodyText }}
  </v-snackbar>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    show: false,
    color: "",
    text: "",
    timeout: 0,
  }),
  computed: {
    ...mapGetters(["notificationState"]),
    bodyText() {
      const self = this;
      let text = "";
      if (self.notificationState.lngKey && !self.notificationState.text) {
        text = self.$t(self.notificationState.lngKey);
      } else if (
        !self.notificationState.lngKey &&
        self.notificationState.text
      ) {
        text = self.notificationState.text;
      }
      return text;
    },
  },
  created() {
    const self = this;
    self.$store.subscribe((mutation, state) => {
      if (mutation.type === "closeToast") {
        self.show = false;
      }
      if (
        mutation.type === "setNotificationProps" &&
        state.notifications.commonProps.type == 1
      ) {
        self.text = state.notifications.commonProps.text;
        self.color = state.notifications.commonProps.color;
        self.timeout = state.notifications.commonProps.timeout;
        self.show = true;
      }
    });
  },
  methods: {
    ...mapActions(["closeNotifications"]),
    onClose() {
      const self = this;
      self.$nextTick(() => {
        setTimeout(() => {
          self.show = false;
          self.$store.dispatch("closeNotifications");
        }, self.timeout);
      });
    },
  },
};
</script>

<style>
</style>