import api from '@/api/preferences'

const Preferences = {
    state: {
        userPreferences: {
            country_id: null,
            language_id: null,
            currency_id: null,
        }
    },
    getters: {
        preferences: state=> state.userPreferences,
    },
    mutations: {
        setUserPreferences(state, data){
            state.userPreferences = data;
        },
    },
    actions: {
        async getPreferences({ commit }) {
            const { data } = await api.get();
            commit('setUserPreferences', data);
            return data;
        }
    },
}
export default Preferences