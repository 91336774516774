import axios from '@/plugins/axios'

const Products = {
    all() {
        return axios({ requiresAuth: true }).get('companies');
    },
    show(id){
        return axios({ requiresAuth: true }).get(`companies/${id}`);
    },
    byUserId(id){
        return axios({ requiresAuth: true }).get(`company/user/${id}`);
    },
    byTenantId(id){
        return axios({ requiresAuth: true }).get(`company/tenant/${id}`);
    }
}

export default Products