import stripeApi from "@/api/stripe";

const Stripe = {
    state: {
        marketplaceSubscriptionIsActive: false,
        inventorySubscriptionIsActive: false,
        warehouseSubscriptionIsActive: false,
        marketplaceTotalSubscriptions: 0,
        inventoryTotalSubscriptions:0,
        warehouseTotalSubscriptions: 0,
        inventorySubscriptionsData: [],
        warehouseSubscriptionsData: [],
        marketplaceSubscriptionsData: [],
    },
    getters: {
        allSubscriptionsAreInactive(state) {
            return !state.marketplaceSubscriptionIsActive && !state.inventorySubscriptionIsActive && !state.warehouseSubscriptionIsActive;
        },

        isInventorySubscriptionActive(state) {
            return state.inventorySubscriptionIsActive;
        },

        isWarehouseSubscriptionActive(state) {
            return state.warehouseSubscriptionIsActive;
        },

        isMarketPlaceSubscriptionActive(state) {
            return state.marketplaceSubscriptionIsActive;
        },

        getInventorySubscriptionsData(state) {
            return state.inventorySubscriptionsData;
        },

        getWarehouseSubscriptionsData(state) {
            return state.warehouseSubscriptionsData;
        },

        getMarketplaceSubscriptionsData(state) {
            return state.marketplaceSubscriptionsData;
        }
    },
    mutations: {
        marketplaceSubscriptionStatus(state, payload) {
            state.marketplaceSubscriptionIsActive = payload.active;
        },

        inventorySubscriptionStatus(state, payload) {
            state.inventorySubscriptionIsActive = payload.active;
        },

        warehouseSubscriptionStatus(state, payload) {
            state.warehouseSubscriptionIsActive = payload.active;
        },

        setSubscriptionsDefaultStatus(state, payload) {
            state.marketplaceSubscriptionIsActive = payload.active;
            state.inventorySubscriptionIsActive = payload.active;
            state.warehouseSubscriptionIsActive = payload.active;
        },

        marketplaceTotalSubscriptions(state, payload) {
            state.marketplaceTotalSubscriptions = payload.total;
        },

        inventoryTotalSubscriptions(state, payload) {
            state.inventoryTotalSubscriptions = payload.total;
        },

        warehouseTotalSubscriptions(state, payload) {
            state.warehouseTotalSubscriptions = payload.total;
        },

        setSubscriptionTotalDefaults(state, payload) {
            state.marketplaceTotalSubscriptions = payload.marketplaceTotal;
            state.warehouseTotalSubscriptions = payload.warehouseTotal;
            state.inventoryTotalSubscriptions = payload.inventoryTotal;
        },

        setInventorySubscriptionsData(state, payload) {
            state.inventorySubscriptionsData = payload.inventoryData;
        },

        setWarehouseSubscriptionsData(state, payload) {
            state.warehouseSubscriptionsData = payload.wmsData;
        },

        setMarketplaceSubscriptionsData(state, payload) {
            state.marketplaceSubscriptionsData = payload.marketplaceData;
        }
    },
    actions: {
        async getTenantActiveSubscriptions({ commit, }, info) {
            const { data } = await stripeApi.getTenantActiveSubscriptions(info);

            let subscriptions = data;

            //initialize variables
            commit({
                type: "setSubscriptionTotalDefaults",
                marketplaceTotal: 0,
                inventoryTotal: 0,
                warehouseTotal: 0,
            })

            commit({
                type: "setSubscriptionsDefaultStatus",
                active: false,
            });


            if (subscriptions.MARKETPLACE) {
                commit({
                    type: "marketplaceSubscriptionStatus",
                    active: true
                });

                commit({
                    type: "marketplaceTotalSubscriptions",
                    total: subscriptions.MARKETPLACE
                });
            }

            if (subscriptions.INVENTORY) {
                commit({
                    type: "inventorySubscriptionStatus",
                    active: true
                });

                commit({
                    type: "inventoryTotalSubscriptions",
                    total: subscriptions.INVENTORY
                });
            }

            if (subscriptions.WAREHOUSE) {
                commit({
                    type: "warehouseSubscriptionStatus",
                    active: true
                });

                commit({
                    type: "warehouseTotalSubscriptions",
                    total: subscriptions.WAREHOUSE
                });
            }
        },

        async getSubscriptionsData({ commit, }) {
            const { data } = await stripeApi.getSubscriptionsData();

            commit({
                type: "setInventorySubscriptionsData",
                inventoryData: data.inventoryData
            });

            commit({
                type: "setWarehouseSubscriptionsData",
                wmsData: data.wmsData
            });

            commit({
                type: "setMarketplaceSubscriptionsData",
                marketplaceData: data.marketplaceData
            });
        }
    },
};
export default Stripe;

