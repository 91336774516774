<template>
  <v-app-bar
    id="main_navbar"
    ref="main_navbar"
    class="mx-auto"
    app
    color="white"
    fixed
    elevate-on-scroll
    v-scroll="onScroll"
  >
    <v-app-bar-nav-icon
      class="elevation-3"
      v-if="$vuetify.breakpoint.mobile"
      @click="setDrawerState(!drawer)"
    >
      <v-avatar>
        <v-img
          contain
          alt="Allec Logo"
          :src="require('../../assets/Allec-New-Logo-A-Icon.png')"
          transition="scale-transition"
        />
      </v-avatar>
    </v-app-bar-nav-icon>
    <v-app-bar-title class="ml-n7"  v-if="!$vuetify.breakpoint.mobile">
      <span @click="toRoute()">
        <v-img
          eager
          alt="Allec Logo Name"
          class="ml-0 shrink hidden-sm-and-down"
          contain
          :src="require('../../assets/Allec-New-Logo-A.png')"
          width="130"
        />
      </span>
    </v-app-bar-title>
    <v-divider vertical class="mx-6" inset></v-divider>

    <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>

    <search-autocomplete></search-autocomplete>

    <v-spacer v-if="!$vuetify.breakpoint.mobile"></v-spacer>

<!--    <lang-menu v-if="!$vuetify.breakpoint.mobile" />-->
<!---->
<!--    <invoices-menu v-if="isLoggedIn && !$vuetify.breakpoint.mobile" />-->
<!---->
<!--    <notifications-menu v-if="!$vuetify.breakpoint.mobile" />-->
<!---->
    <user-avatar v-if="!$vuetify.breakpoint.mobile" />
  </v-app-bar>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
// import NotificationsMenu from "./NotificationsBtnMenu";
// import LangMenu from "./LangBtnMenu";
// import InvoicesMenu from "./InvoicesBtnMenu";
import UserAvatar from "@/components/user/Avatar.vue";
import { mapGetters, mapMutations, mapState } from "vuex";
import SearchAutocomplete from "./SearchAutocomplete.vue";
export default {
  name: "Navbar",
  components: {
    // NotificationsMenu,
    // LangMenu,
    UserAvatar,
    // InvoicesMenu,
    SearchAutocomplete,
  },

  data() {
    return {
      toggle_exclusive: null,
      fav: false,
      menu: false,
      loading: false,
      items: [],
      search: null,
      select: null,
    };
  },
  computed: {
    ...mapState(["drawer"]),
    ...mapGetters(["isLoggedIn", "currentUserType"]),
  },
  methods: {
    ...mapMutations(["setDrawerState"]),
    onScroll() {
      const self = this;
      const currentScroll = self.$refs.main_navbar.currentScroll;
      const breakpoint = self.$vuetify.breakpoint.mobile;
      if (!breakpoint) {
        self.$nextTick(() => {
          if (currentScroll > 3) {
            anime({
              duration: 15,
              targets: "#main_navbar",
              translateY: 16,
              width: "96vw",
              easing: "easeInQuad",
            });
          } else if (currentScroll <= 100) {
            anime({
              duration: 30,
              targets: "#main_navbar",
              translateY: 0,
              width: "100vw",
              easing: "easeInOutQuad",
            });
          }
        });
      }
    },

    toRoute() {
        // if(this.currentUserType.key === 'master_admin') {
        //   this.$router.push('/tenant/menu');
        // }
        // else {
      this.$router.push({name: "Home"});
        // }
    }
  },
};
</script>

<style>
</style>