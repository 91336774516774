import axios from '@/plugins/axios'

const Suppliers = {
    create(data){
        return axios({ requiresAuth: true }).post("api/tenants/" + data.tenant_id + "/suppliers", data);
    },
    update(data) {
        console.log("Data: ", data)
        delete data.data;
        var formData = new FormData();
        for (var key in data) {
          formData.append(key, data[key]);
        }
        return axios({ requiresAuth: true, isFormData: false }).patch(
            "api/tenants/" + data.tenant_id + "/suppliers",
          data
        );
    },
    all() {
        return axios({ requiresAuth: true }).get('suppliers');
    },
    show(id){
        return axios({ requiresAuth: true }).get(`suppliers/${id}`);
    },
    byTenant(id){
        return axios({ requiresAuth: true }).get(`suppliers/tenant/${id}`);
    }
}

export default Suppliers
