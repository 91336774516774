import ordersApi from "../../api/orders";

const Orders = {
  state: {
    all: [],
    byTenant: []
  },
  getters: {
    orders: (state) => state.all,
    ordersByTenant: (state) => state.byTenant,
  },
  mutations: {
    setOrders(state, orders) {
      state.all = orders;
    },
    setOrdersTenant(state, orders) {
      state.byTenant = orders.inventory;
    },
  },
  actions: {
    async getAllOrders({ commit }) {
      const response = await ordersApi.all();
      if (!response.data.data.orders_marketplace.length) return null;
      const data  = response.data.data.orders_marketplace;
      commit("setOrders", data);
      return data;
    },
    async getOrdersByTenant({ commit }, tenantId) {
      const response = await ordersApi.byTenant(tenantId);
      const { data } = response.data;
      commit("setOrdersTenant", data);
      return data;
    },
  },
};
export default Orders;
