import axios from '@/plugins/axios'

const Orders = {
    all() {
        return axios({ requiresAuth: true }).get('orders');
    },
    show(id){
        return axios({ requiresAuth: false }).get(`orders/${id}`);
    },
    somethingLike(name){
        return axios({ requiresAuth: false }).get(`orders/${name}`);
    },
    byTenant(id){
        return axios({ requiresAuth: true }).get(`orders/tenant/${id}`);
    },
}

export default Orders