const Notifications = {
  state: {
    notifications: [],
    count: 0,
    commonProps: {
      position: "bottom",
      icon: "",
      state: false,
      indeterminate: false,
      persistent: false,
      color: "",
      text: "",
      timeout: 2000,
      lngKey: "",
    },
  },
  getters: {
    notificationState: (state) => state.commonProps,
    notifications: (state) => state.notifications,
    notificationsCount: (state) => state.count,
  },
  mutations: {
    /* Simbolic function */
    closeToast() {},
    closeDialog() {},
    closeOverlay() {},
    incrementNotification(state) {
      state.count++;
    },
    addNotification(state, notification) {
      state.notifications.push(notification);
    },
    setNotificationProps(state, props) {
      state.commonProps.position = props.position
        ? props.position
        : state.commonProps.position;
      state.commonProps.type = props.type;
      state.commonProps.icon = props.icon;
      state.commonProps.state = props.state;
      state.commonProps.color = props.color;
      state.commonProps.text = props.text;
      state.commonProps.lngKey = props.lngKey;
      state.commonProps.indeterminate = props.indeterminate;
      state.commonProps.persistent = props.persistent;
      state.commonProps.timeout = props.timeout
        ? props.timeout
        : state.commonProps.timeout;
    },
    setDefaultsProps(state) {
      state.commonProps = {
        position: "bottom",
        icon: "",
        state: false,
        indeterminate: false,
        persistent: false,
        color: "",
        text: "",
        timeout: 2000,
        lngKey: "",
      };
    },
  },
  actions: {
    showToast({ commit }, props = null) {
      if (!props) return;
      props.type = 1;
      commit("setNotificationProps", props);
    },
    showDialog({ commit }, props = null) {
      if (!props) return;
      props.type = 2;
      commit("setNotificationProps", props);
    },
    showOverlay({ commit }, props = null) {
      if (!props) return;
      props.type = 3;
      commit("setNotificationProps", props);
    },
    /**
     * Types {Number} : 1- Toast, 2- Dialog, 3-Overlay
     */
    showNotification({ dispatch }, props = null) {
      if (props && !props.type) return;
      switch (props.type) {
        case 1:
          dispatch("showToast", props);
          break;
        case 2:
          dispatch("showDialog", props);
          break;
        case 3:
          dispatch("showOverlay", props);
          break;
      }
    },
    closeNotifications({ commit }, type = 0) {
      switch (type) {
        case 0:
          commit("closeToast");
          commit("closeDialog");
          commit("closeOverlay");
          break;
        case 1:
          commit("closeToast");
          break;
        case 2:
          commit("closeDialog");
          break;
        case 3:
          commit("closeOverlay");
          break;
      }
    },
  },
};
export default Notifications;
