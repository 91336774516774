<template>
  <section>
    <!-- Toast -->
    <toast ref="global_snackbar"/>

    <!-- Dialog -->
    <message-dialog ref="global_dialog" />

    <!-- Overlay -->
    <overlay ref="global_overlay" />
  </section>
</template>

<script>
import MessageDialog from "./Dialog.vue";
import Overlay from "./Overlay.vue";
import Toast from "./Toast.vue";

export default {
  components: {
    Toast,
    MessageDialog,
    Overlay,
  },
};
</script>

<style>
</style>