import axios from '@/plugins/axios'
const User = {
    get() {
        return axios({ requiresAuth: true }).get('api/auth/user');
    },
    fetchUserCallback(callback) {
        const self = this;
        const session = localStorage.getItem('session')? JSON.parse(localStorage.getItem('session')) : {};
        if (!session || !session.access_token) {
            callback(null);
        } else {
            const request = self.get()
            callback(request);
        }
    },
    getProfile(){
        return axios({ requiresAuth: true }).get('userprofile');
    },
    storeAdminUser(data) {
        return axios({ requiresAuth: true }).post(`api/user/admin`, data);
    },
    getAllAdminUsers() {
        return axios({ requiresAuth: true }).get('api/user/admin');
    },
}

export default User