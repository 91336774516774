<template>
  <v-app>
    <v-main>
      <transition name="page" mode="out-in">
        <router-view></router-view>
      </transition>
    </v-main>
  </v-app>
</template>

<script>
export default {};
</script>

<style>
</style>