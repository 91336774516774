import { mapActions } from "vuex";

export default {
  methods: {
    ...mapActions([
      'showNotification',
      'closeNotifications',
    ]),
  }
}
